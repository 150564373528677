var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { staticClass: "schema-form-person-field" },
        [
          _c("schema-form-field-label", {
            attrs: {
              label: _vm.fieldTitle,
              "help-text": _vm.field.fieldHelpText,
            },
          }),
          !_vm.disabled
            ? _c("b-form-select", {
                attrs: { disabled: _vm.disabled, options: _vm.peopleOptions },
                on: { input: _vm.personChosen },
                model: {
                  value: _vm.person,
                  callback: function ($$v) {
                    _vm.person = $$v
                  },
                  expression: "person",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { xs: "12", md: "6" } },
            [
              _vm.firstNameField
                ? _c("schema-form-field", {
                    attrs: {
                      field: _vm.firstNameField,
                      disabled: _vm.disabled,
                      "contextual-jurisdiction": _vm.contextualJurisdiction,
                    },
                    on: {
                      "suggestion-toggled": function ($event) {
                        return _vm.$emit("suggestion-toggled", $event)
                      },
                      input: _vm.onInputOtherField,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { xs: "12", md: "6" } },
            [
              _vm.lastNameField
                ? _c("schema-form-field", {
                    attrs: {
                      field: _vm.lastNameField,
                      disabled: _vm.disabled,
                      "contextual-jurisdiction": _vm.contextualJurisdiction,
                    },
                    on: {
                      "suggestion-toggled": function ($event) {
                        return _vm.$emit("suggestion-toggled", $event)
                      },
                      input: _vm.onInputOtherField,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.otherFields, function (subField) {
        return _c("schema-form-field", {
          key: subField.id,
          attrs: {
            field: subField,
            disabled: _vm.disabled,
            "contextual-jurisdiction": _vm.contextualJurisdiction,
          },
          on: {
            "suggestion-toggled": function ($event) {
              return _vm.$emit("suggestion-toggled", $event)
            },
            input: _vm.onInputOtherField,
          },
        })
      }),
      _vm.addressField
        ? _c("schema-form-field", {
            ref: "person-address-field",
            attrs: {
              field: _vm.addressField,
              disabled: _vm.disabled,
              "contextual-jurisdiction": _vm.contextualJurisdiction,
              "ra-address": _vm.raAddress,
            },
            on: {
              "ra-signup": function ($event) {
                return _vm.$emit("ra-signup")
              },
              "suggestion-toggled": function ($event) {
                return _vm.$emit("suggestion-toggled", $event)
              },
              input: _vm.onInput,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }